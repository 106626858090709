
import {request} from "../index";
function getShopList(sort,min_bean,max_bean,page,name,dura,rarity,type){
    if(name !== ''){
        return request({
            url: `/shop?name=${name}&sort=${sort}&page=${page}&type=${type}`,
            method:'get'
        })
    }
    if(dura !== '' && rarity !== ''){
        return request({
            url: `/shop?sort=${sort}&page=${page}&rarity=${rarity}&dura=${dura}&type=${type}`,
            method:'get'
        })
    }
    if(dura !== '' && rarity === ''){
        return request({
            url: `/shop?sort=${sort}&page=${page}&dura=${dura}&type=${type}`,
            method:'get'
        })
    }
    if(rarity !== ''&& dura === ''){
        return request({
            url: `/shop?sort=${sort}&page=${page}&rarity=${rarity}&type=${type}`,
            method:'get'
        })
    }
    if(min_bean&&max_bean){

        return request({
            url: `/shop?sort=${sort}&min_bean=${min_bean}&max_bean=${max_bean}&page=${page}&type=${type}`,
            method:'get'
        })
    }
    return request({
        url: `/shop?sort=${sort}&page=${page}&type=${type}`,
        method:'get'
    })
}


function getShopListType(type){
    return request({
        url: `/shop?type=${type}`,
        method:'get'
    })
}
function getShopBuy(data){
        return request({
            url: `/shop/buy`,
            method:'post',
            data
        })
}
function getShopexchange(data){
    return request({
        url: `/shop/exchange`,
        method:'post',
        data
    })
}
// 积分兑换
//
export default {
    getShopList,
    getShopBuy,
    getShopexchange,
    getShopListType
}
