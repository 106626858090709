<template>
  <div class="">
    <div class="font-weight-bold text-center family headtitle"
      style="-webkit-text-stroke: .5px #9FDA35; margin:2rem 0 1rem;font-size:35px;">
      {{ $route.meta.title }}
    </div>
    <div class="content">

      <!-- 分类 -->
      <div class="d-flex justify-content-between flex-wrap classification">
        <div :class="getluckyTypeActive == index ? 'active' : ''" @click="getluckyTypeClick(item, index)" class="text-center mb-md-4 mb-2  p-2
                     f_shopping_class" style="font-size: 12px" v-for="(item, index) in getluckyType" :key="index">
          <img width="100%" :src="item.cover" alt="">
          <div>{{ item.name }}</div>
        </div>
      </div>


      <div class="d-flex flex-wrap" style="justify-content: center;">
        <div class="d-flex mb-1 updown" style="font-size:16px">
          <div class=" px-3" @click="sort('0')">
            <div>
              升序
            </div>
          </div>
          <div class="px-3" @click="sort('1')">
            <div>
              降序
            </div>

          </div>
        </div>
        <!-- 积分 -->
        <!-- <div class="d-flex mb-1 ">
          <div class="px-3 py-1 cursor-pointer">
            积分
          </div>
          <div class="px-2 py-1">
            {{user_info.integral || 0}}
          </div>
        </div> -->
        <div class="d-flex searchbox">
          <!-- <div class="d-md-flex d-none  mr-3 align-items-center">
            <div class="bg-bg px-2 input">
              <input placeholder="最小金额" v-model="min_bean" style="width: 100px;font-size: 12px;height: 40px"
                     type="text"
                     class="text-white">
            </div>
            <div style="width:40px;height: 2px" class="bg-yellow "></div>
            <div class="bg-bg  px-2 input">
              <input placeholder="最大金额" v-model="max_bean" style="width: 100px;font-size: 12px;height: 40px"
                     type="text" class="text-white" @keyup.enter="filtrateMoney">
            </div>
            <div @click="filtrateMoney" class=" ml-3 rounded-pill f-main-bg cursor-pointer"
                 style="height: 40px;line-height: 40px;width: 200px;text-align: center">
              筛选
            </div>
          </div> -->
          <div class="d-flex" style="align-items: center;">
            <input placeholder="搜索名称" v-model="search" type="text" class="text-white searchinput">
            <div @click="searchName">搜索
            </div>
          </div>

        </div>

      </div>

      <!-- 筛选按钮 -->
      <!-- <div class="d-flex justify-content-between flex-wrap">
        <div class="w-100 mb-2">
          <div class="mt-4 d-flex">
            <div class="" style="width: 70px;line-height: 16px;">外观：</div>
            <div class="d-md-block d-flex shaixuan" style="width: calc(100% - 70px);font-size: 8px;">

                        <span class="mr-3 cursor-pointer px-4 d-inline-block rounded-pill  text-nowrap"
                              style="line-height: 16px;font-size: 8px"
                              @click="unlimited('','')"
                              :class="search_dura_value === '' ?' f-main-bg' : ''"
                        >不限</span>
              <span class="mr-3 cursor-pointer px-3 py-md-2 mb-md-3  rounded-pill text-nowrap"
                    style="line-height: 16px" @click="search_dura_value_click(item,index)"
                    :class="index === search_dura_value?' f-main-bg' : ''" v-for="(item,index) in search_dura"
                    :key="index">{{item}}</span>
            </div>
          </div>

          <div class="d-flex mt-3">
            <div class="" style="width: 70px;line-height: 16px;">品质：</div>
            <div class="d-md-block d-flex shaixuan flex-nowrap overflow-auto" style="width: calc(100% - 70px);font-size: 8px">

                        <span class="mr-3 cursor-pointer px-4 d-inline-block rounded-pill  text-nowrap"
                              style="line-height: 16px;font-size: 8px"
                              @click="unlimitedWG('','')"
                              :class="search_rarity_value === '' ?' f-main-bg' : ''"
                        >不限</span>
              <span class="mr-3 cursor-pointer px-4 py-md-2 mb-md-3  d-inline-block rounded  text-nowrap"
                    style="line-height: 16px"
                    @click="search_rarity_value_click(item,index)"
                    :class="index === search_rarity_value?' f-main-bg' : ''" v-for="(item,index) in search_rarity"
                    :key="index">{{item}}</span>
            </div>
          </div>
        </div>

      </div> -->


      <div class="content-list my-5">

        <div class="mb-2 weapon" style="" v-for="(item, index) in shoppingList" :key="index">
          <weapon style="width: 90%" @click.native="shoppinng(item, index)" :res-data="item">
          </weapon>
        </div>

        <vacancy class="d-flex justify-content-center" v-if="shoppingList.length == 0"></vacancy>
      </div>
      <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize"
        @current-change="currentChange">
      </el-pagination>
    </div>


    <!-- 立即购买 -->
    <div v-show="immediatelyShopping">
      <div style="" class="position-fixed pop-up z-index-100 py-5 immediatelyShopping f-border-top">
        <div class="pt-5 px-5 immediatelyShopping-box">
          <div class="d-md-flex immediatelyShopping-box-con">
            <div class="px-2 immediatelyShopping-box-con-img" style="border-radius:15px;" :class="'ggbg' + Shopping.dura">
              <img width="250px" :src="Shopping.cover" alt="">
            </div>
            <div class="ml-5 immediatelyShopping-box-con-dis">
              <div class="mb-3" style="line-height: 24px,font-size:20px;">{{ Shopping.name }}</div>
              <div class="mt-3" style="font-size:16px;">
                {{ Shopping.dura_alias }}
              </div>
              <div class="mt-3">
                <el-input-number v-model="num" controls-position="right" :min="1"></el-input-number>
              </div>
              <div class="mt-3">
                积分：{{ (Shopping.mall_integral * num).toFixed(2) }}
              </div>


            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div @click="ShoppingMoney">
              <bottom-bg style="width:80px;height:40px;line-height: 40px;">
                <div class="" style="font-size: 16px;  color: black;font-weight:bold;">
                  积分购买
                </div>
              </bottom-bg>
            </div>
          </div>
          <div @click="immediatelyShopping = false" class="position-absolute" style="right: 20px;top: 20px">
            <div>
              <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:30px"></i>
            </div>
          </div>

        </div>
      </div>
      <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;">

      </div>
    </div>
  </div>
</template>

<script>
import bottomBg from "@/components/bottomBg";
import weapon from "./common/weapon";
import $api from '@/api/shopping/index'
import $luckyType from '@/api/lucky/index'
import { mapState } from 'vuex'

export default {
  name: "index",
  components: {
    bottomBg,
    weapon,
  },
  data() {
    return {
      // 弹出框
      immediatelyShopping: false,
      type: 1,
      // 选择数量
      num: 1,
      // 渲染商城列表
      shoppingList: [],
      // 翻页
      total: 0,
      pageSize: 0,
      // 价格区间
      min_bean: '',
      max_bean: '',
      // 排序
      sortSum: 0,
      // 弹出的武器信息
      Shopping: {},
      // 更具名称搜索
      search: '',
      search_dura_value: '',
      search_dura: ['无', '崭新出厂', '略有磨损', '久经沙场', '破损不堪', '战痕累累', '无涂装'],
      search_rarity_value: '',
      search_rarity: ['无', '消费级', '工业级', '军规级', '受限', '保密', '隐秘', '普通级', '高级', '非凡', '奇异', '卓越', '违禁', '探员:高级', '探员:卓越', '探员:非凡', '探员:大师'],
      getluckyType: [],
      getluckyTypeActive: false,

    }
  },
  computed: {
    ...mapState(['user_info'])
  },
  created() {

  },
  mounted() {
    this.init()
  },
  methods: {
    // 点击分类
    async getluckyTypeClick(item, index) {
      this.getluckyTypeActive = index
      this.type = item.id
      let { data } = await $api.getShopListType(item.id)
      this.shoppingList = data.data
      this.total = data.total
      this.pageSize = data.per_page

    },
    // 增加搜索
    async searchName() {
      await this.getShopList(this.sortSum, this.min_bean, this.max_bean, this.num, this.search,)
    },
    async search_dura_value_click(item, index) {
      this.search_dura_value = index
      if (this.search_rarity_value !== '') {
        await this.getShopList(this.sortSum, this.min_bean, this.max_bean, this.num, '', index, this.search_rarity_value)
      } else {
        await this.getShopList(this.sortSum, this.min_bean, this.max_bean, this.num, '', index,)
      }
    },
    async search_rarity_value_click(item, index) {
      this.search_rarity_value = index
      if (this.search_dura_value !== '') {
        await this.getShopList(this.sortSum, this.min_bean, this.max_bean, this.num, '', this.search_dura_value, index)
      } else {
        await this.getShopList(this.sortSum, this.min_bean, this.max_bean, this.num, '', '', index,)
      }

    },
    unlimited(t) {
      this.search_rarity_value = t
      this.search_dura_value = t
      this.getShopList()
    },
    unlimitedWG(t) {
      this.search_rarity_value = t
      this.search_dura_value = t
      this.getShopList()
    },
    init() {
      this.getShopList()
      this.getluckyTypeList()
    },
    shoppinng(item) {
      document.getElementById('mysound').play()
      this.immediatelyShopping = true
      this.Shopping = item
    },
    async getShopList(sort = 0, min_bean = '', max_bean = '', page = 1, name = '', dura = '', rarity = '') {
      try {
        let { data, code } = await $api.getShopList(sort, min_bean, max_bean, page, name, dura, rarity, this.type)
        if (code == 500) return
        this.shoppingList = data.data
        this.total = data.total
        this.pageSize = data.per_page
      } catch (e) {
        console.log(e)
      }
    },
    async getluckyTypeList() {
      try {
        let { data } = await $luckyType.getluckyType()
        this.getluckyType = data
        this.type = data[0].id
      } catch (e) {
        console.log(e)
      }

    },
    sort(sum) {
      this.sortSum = sum
      this.getShopList(sum, this.min_bean, this.max_bean, 1)
    },
    filtrateMoney() {
      this.getShopList(this.sortSum, this.min_bean, this.max_bean, 1)
    },
    async ShoppingIntegral() {
      try {
        document.getElementById('mysound').play()
        let { code, message } = await $api.getShopBuy({ id: this.Shopping.id, num: this.num })
        if (code == 500) return
        if (code == 200) {
          this.$notify({
            title: message,
            type: 'success',
            position: 'top-left'
          });
          this.$store.dispatch('userInfo')
        }
      } catch (e) {
        console.log(e)
      }

      this.immediatelyShopping = false

    },
    async ShoppingMoney() {
      try {
        document.getElementById('mysound').play()
        let { code, message } = await $api.getShopexchange({ id: this.Shopping.id, num: this.num })
        if (code == 500) return
        if (code == 200) {
          this.$notify({
            title: message,
            type: 'success',
            position: 'top-left'
          });
          this.$store.dispatch('userInfo')
        }
      } catch (e) {
        console.log(e)
      }
      this.immediatelyShopping = false

    },
    currentChange(val) {
      this.getShopList(0, '', '', val)
    }
  }
}
</script>

<style scoped lang="scss">
.f_shopping_class {
  width: calc(100% / 8);

  @media (max-width: 960px) {
    width: 25%;
  }
}

.shaixuan {
  @media (max-width: 960px) {
    overflow-x: scroll;
  }

  &::-webkit-scrollbar {
    height: 2px;
  }
}

.immediatelyShopping {
  z-index: 3;
  width: 300px !important;
  height: 535px !important;
  left: calc(50% - 300px);
  top: calc(10%);

  @media (max-width: 1200px) {
    left: 10.5%;

    &-box {
      padding-left: 10px !important;
      padding-right: 10px !important;

      &-con {
        &-img {
          width: 50%;
          margin: 0 auto;
        }

        & img {
          width: 100%;
        }

        &-dis {
          margin-left: 20px !important;
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
}

.weapon {
  width: 175px;
}

.content {
  width: 90%;
  margin: auto;
  overflow: hidden;

  @media (max-width: 1366px) {
    width: 95%;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between !important;
  }
}

.icon-size {
  font-weight: bold;
  font-size: 14px !important;
}

.icon-zhuan {
  font-weight: bold;
  transform: rotate(180deg);
}

.active {
  //box-shadow: 1px 1px 1px 1px #9fda35;
  //border-radius: 15px;
  color: RGBA(159, 218, 53, 1);
}

.headtitle {
  color: rgba(255, 255, 255, 0);
}


.searchinput {
  box-shadow: 0 0 2px 1px rgb(0, 204, 255) inset;
  width: 250px;
  height: 45px;
  border-radius: 20px;
  margin-right: 5px;
  font-size: 15px;
  padding-left: 20px;
}

.searchbox {
  width: 100%;
  justify-content: center !important;
  align-items: center !important;
}

.searchbox>div>div {
  width: 50px;
  height: 50px;
  font-size: 15px;
  background-color: #96ca3b;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  border: 3px solid #01a1d9;
}

.updown>div {
  height: 40px;
  box-shadow: 0 0 40px 4px #568d56 inset;
  line-height: 40px;
  border-radius: 8px;
  margin-left: 5px;
}

.classification {
  margin-bottom: 10px;
  padding-top: 10px;
  border-radius: 15px;
  box-shadow: 0 0 16px 5px #097ae3 inset;
}
</style>
