<template>
  <!-- 枪支的item，基本公共样式 - 看接口返回的信息需要 -->
  <div class="d-flex align-items-center flex-column pb-2 gunAllBg"
        style="width:100%;" >
    <div class="d-flex justify-content-center w-100 p-2" style="font-size:16px;" >
      <div class="text-white"><money :integral="false"/>{{resData.mall_integral}}</div>
    </div>
  <div style="height: 80px">
    <img height="100%" class="position-relative z-index-1"
         :src="resData.cover" alt="">
  </div>

    <div class="px-2 text-center align-items-center d-flex"
         style="line-height: 24px;font-size: 12px ">  {{resData.dura_alias}} </div>
    <div class="px-2 text-center  border-top-0  text-overflow-1"
         style="line-height: 24px;font-size:12px">
    {{resData.name}}
    </div>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "weapon",
    props:{
      // 传输武器整体宽度
      width:{
        type:String,
        default:"180px"
      },
      // 武器显示的高度，宽度和整体宽度是一样的
      heightWeapon:{
        type:String,
        default:"150px"
      },
      // 传输背景 style 对象形式传输
      bgSize:{
        type:Object,
        default(){
          return {
            height: '102px'
          }
        }
      },
      // 武器对应的宽度 高度 带单位 style 对象形式传输
      weaponSize:{
        type:Object,
        default(){
          return {
            width: '115px',
            marginTop:'25px'
          }
        }
      },
      //    传输的item
      resData:{
        type:Object,
        default(){return {}}
      }
    },
  }
</script>

<style scoped lang="scss">
  .weapon{
    &-name{
      padding: 10px 0;
    }
  }
.gunAllBg{
  box-shadow: 0 0 20px 3px #9fda35 inset;
  border-radius: 15px;
  margin: auto;
}  
</style>
